import React,{ useState }  from 'react'
import { auth, provider, db } from '../firebase'
import { addDoc, collection } from 'firebase/firestore'
import { signInWithPopup, signOut } from 'firebase/auth'
import { storage } from '../firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { VStack, Button, Box, Input, Image, Select, Text } from '@chakra-ui/react'
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from 'react-router-dom'


const CreatePost = () => {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [lang, setLang] = useState("");
  const postCollectionRef = collection(db, 'posts');

  // Variables y funciones post de imagen
  const [imageData, setImageData] = useState({})
  var imageUrl = ""
  const [bgImageData, setbgImageData] = useState({})
  var bgImageUrl = ""


  const handleImageFile = async (e) => {
    const image = e.target.files[0]
    setImageData(image)
  }

  const handleBackgroundImageFile = async (e) => {
    const image = e.target.files[0]
    setbgImageData(image)
  }


  const handleImageUpload = async () => {
    const imgRef = ref(storage,`/images/${imageData.name}`)
    await uploadBytes(imgRef, imageData).then(
    );
    await getDownloadURL(imgRef).then((url) => {
      imageUrl = url
    });
  }

  const handleBackgroundImageUpload = async () => {
    const bgImgRef = ref(storage,`/bgImages/${bgImageData.name}`)
    await uploadBytes(bgImgRef, bgImageData).then(
    );
    await getDownloadURL(bgImgRef).then((url) => {
      bgImageUrl = url
    });
  }

  const createPost = async () => {
    await handleImageUpload();
    await handleBackgroundImageUpload();
    await addDoc(postCollectionRef, {title,
                                    body,
                                    lang,
                                    imageUrl,
                                    bgImageUrl,
                                    author:auth.currentUser.displayName})
    alert('Post creado')
    navigate('/blog')
  };
  
  const signInWithGoogle = () =>{
    signInWithPopup(auth, provider).then((result) =>{
      localStorage.setItem("isAuth", true)
      setIsAuth(true)
    })
  }
  
  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear()
      setIsAuth(false)
    })
  }



  return (
    <VStack >
      <Box padding={10}>
        {!isAuth ? (
          <Button onClick={signInWithGoogle}>Sign in with Google</Button>
        ) :
        (
          <VStack >
            <Button  onClick={signUserOut}>Logout</Button>
            <VStack p={10}>
              <Input placeholder='Title' onChange={(e) => setTitle(e.target.value)}>
              </Input>
              <Select
                  onChange={(e) => setLang(e.target.value)} 
                  placeholder='Language'
                  >
                <option value='ES'>Español</option>
                <option value='EN'>English</option>
              </Select>
              <Editor
                textareaName='Body'
                initialValue="<p>This is the initial content of the editor.</p>"
                init={{
                    height: 500,
                    menubar: true,
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                onEditorChange={(newText) => setBody(newText)}
            />
              <Box w='100%'>
                <Text fontSize='sm' pt='4%'>Imagen principal (formato libre)</Text>
                <Input type='file' size='md' onChange={handleImageFile}/> 
                <Text fontSize='sm' pt='2%'>Imagen preview (horizontal; 1920x1080) </Text>
                <Input type='file' size='md' onChange={handleBackgroundImageFile}/>
              </Box>
            </VStack>
            <Button onClick={(e) => {createPost(e)}}> Crear Post</Button>
            <Image src={imageUrl}></Image>
          </VStack>
          
          
        )}
      </Box>
    </VStack>
  )
}

export default CreatePost;