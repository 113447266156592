import {Heading, VStack,Stack,Text,Image, ScaleFade } from '@chakra-ui/react'
import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";

const Services = ({ text }) => {


  const ref = useRef(null);
  const { enterCount } = useInViewport(
    ref,
    { rootMargin: "-100px" },
    { disconnectOnLeave: false },
    {}
  );
  var md = require('markdown-it')({ breaks: true });
  const subHTML = md.render(text.submd);

  return (
    <VStack  id='services'  mx={['10vw','0vw'] } mt='96px' pos='relative' >
      <ScaleFade
          initialscale={1}
          in={enterCount > 0}          
        >
      <Stack direction={['column', 'row']} alignItems='center'>
        <Heading 
          py={[2,10]} 
          fontSize={['38px','64px']}
          textAlign='center'
          ref={ref}
          >{text.tit}
          </Heading> 
          <Image src='logo2.png' w={['200px','300px']} objectFit='contain'/>
      </Stack>
        </ScaleFade>
        <ScaleFade
          initialscale={1}
          in={enterCount > 0}         
        >
      <Text fontSize='15px' align='center' dangerouslySetInnerHTML={{__html: subHTML}} ref={ref} pt={[4,0]}></Text>     

      <Stack direction={['column','row']} fontSize='18px' maxW={['100%', '100%']} justifyContent='center' pt={'4em'} spacing='36px' > 
        <VStack bgGradient='linear(to-b, #EEEEEE, #E6E6E6)' p={5} width={['100%','35vw']} boxShadow={'lg'} _hover={{ transform:'scale(1.03)', transition: '1s ease-out'}}>
          <VStack h={['100%','60%']}>
            <Image src='/services1.png'></Image>
            <Heading  fontWeight={800} fontSize={'24px'} py={5}> {text.stack1[0]} </Heading>
          </VStack>
          <VStack textAlign='justify' py={1} >
            <Text > {text.stack1[1]} </Text>
            <Text align='center' fontSize='13px' py={2} maxW={[ '100%', '80%']}>{text.stack1[2]}</Text>
          </VStack>
          {/*<Icon as={numberOneIcon} w='30px' h='30px' mb={8} boxShadow={'lg'} borderRadius={'50%'}></Icon>*/}
        </VStack>      

      <VStack bgGradient='linear(to-b, #EEEEEE, #E6E6E6)' p={5} width={['100%','35vw']}  boxShadow={'lg'}  _hover={{ transform:'scale(1.03)', transition: '1s ease-out'}}>
        <VStack h={['100%','60%']} >
            <Image src='/services2.png'></Image>
            <Heading textAlign='center' fontSize={'24px'} py={5} fontWeight={800} > {text.stack2[0]} </Heading>
            </VStack>
          <VStack textAlign='justify' py={1} >
            <Text> {text.stack2[1]} </Text>
            <Text align='center' fontSize='13px'  py={2} maxW={[ '100%', '80%']}> {text.stack2[2]}</Text>
          </VStack>
          {/*<Icon as={numberTwoIcon} w='30px' h='30px' mb={8} boxShadow={'lg'} borderRadius={'50%'}></Icon>*/}
        </VStack>
    </Stack>
      </ScaleFade>
    
    <Stack direction={['column','row']} fontSize='18px' maxW={['100%', '100%']} justifyContent='center'  spacing='36px' pt={'36px'}>
      
        <VStack  bgGradient='linear(to-b, #EEEEEE, #E6E6E6)' p={5}  width={['100%','35%']} boxShadow={'lg'} _hover={{ transform:'scale(1.03)', transition: '1s ease-out'}}>
          <VStack h={['100%','60%']}>
            <Image src='/services3.png' ></Image>
            <Heading textAlign='center' fontSize={'24px'}  py={5} fontWeight={800} > {text.stack3[0]} </Heading>
          </VStack>
          <VStack textAlign='justify' py={1} >
            <Text > {text.stack3[1]} </Text>
            <Text align='center' fontSize='13px' py={2} maxW={[ '100%', '80%']}> {text.stack3[2]} </Text>
          </VStack>
          {/*<Icon as={numberThreeIcon} w='30px' h='30px' mb={8} boxShadow={'lg'} borderRadius={'50%'}></Icon>*/}

        </VStack>

        <VStack bgGradient='linear(to-b, #EEEEEE, #E6E6E6)'  p={5}  width={['100%','35%']}  boxShadow={'lg'} _hover={{ transform:'scale(1.03)', transition: '1s ease-out'}}>
          <VStack  h={['100%','60%']} >
            <Image src='/services4.png'></Image>
            <Heading fontSize={'24px'} py={5} fontWeight={800} > {text.stack4[0]} </Heading>
          </VStack>
          <VStack textAlign='justify' py={1}>
            <Text > {text.stack4[1]} </Text>
            <Text align='center' fontSize='13px' py={2} maxW={[ '100%', '80%']} >{text.stack4[2]} </Text>
          </VStack>
          {/*<Icon as={numberFourIcon} w='30px' h='30px' mb={8} boxShadow={'lg'} borderRadius={'50%'}></Icon>*/}
        </VStack>
      </Stack>
    </VStack>
  )
}

export default Services