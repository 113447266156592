import './App.css';
import NavBar from './components/NavBar';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-233979384-1"; 
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div>
    <NavBar />
    </div>
  );
}

export default App;
