import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import { Stack, Text, Image, VStack, Link, HStack, ScaleFade, Heading } from '@chakra-ui/react'
import { LinkedInIcon } from './icons/linkedInIcon';

const Team = ({ text }) => {

  const ref = useRef(null);
  const { enterCount } = useInViewport(
    ref,
    { rootMargin: "-100px" },
    { disconnectOnLeave: false },
    {}
  );

  return (

    <ScaleFade
    initialscale={1}
    in={enterCount > 0}          
  >
    <VStack
      id='team'
      mx={'1vw, 0vw'} 
      mt={'10vh'}
      textAlign={'center'}
      ref={ref}
    >
      <HStack alignItems='center'>
      <Heading 
        fontSize={['30px','36px','54px']} 
        fontWeight='700' 
        align='center'
        mb='2em'
        mt='1.5em'
        >{text[0]}
      </Heading>
      <Image src='services1.png' pb='24px' pl='24px' display={['none','inline']} />
      </HStack>

      <Stack 
        direction={['column','column', 'column', 'row']} 
        spacing={['90px','180px']}
        justify={['space-between','space-between']} 
        align='center'
      >
      <Stack  boxShadow='lg' p='2%' bg={'#fff'}>
        <Image 
          src='/charly.png' 
          alt='charly' 
          w='100%'
          h='100%'
          />
        
        <HStack justifyContent={'center'}>
        <Text 
          className='name' 
          fontSize='20px' 
          lineHeight='120%' 
          fontWeight='700'>Charly</Text>
        <Link href='https://www.linkedin.com/in/carlos-oraldo-galanes-513906246/'  target="_blank"><LinkedInIcon bg='primary' w='20px' h='20px' borderRadius='6px' _hover={ {bg:'#dcf8c1'}}/></Link>
        </HStack>        

        
        <Text 
          className='position' 
          fontSize='14px' 
          lineHeight='150%' 
          fontWeight='500'>CEO</Text>

      </Stack>
        
        <Stack 
          boxShadow='lg' 
          p='2%' 
          bg={'#fff'}
        >
          <Image 
            src='/marto.png'
            alt='marto'
            w='100%'
            h='100%'
          />
          <HStack justifyContent={'center'}>
          <Text 
            className='name' 
            fontSize='20px' 
            lineHeight='120%' 
            fontWeight='700'>Martín</Text>
            <Link href='https://www.linkedin.com/in/mart%C3%ADn-marcaida-093561158/'  target="_blank"><LinkedInIcon bg='primary' w='20px' h='20px' borderRadius='6px' _hover={{bg:'#dcf8c1'}} /></Link>
          </HStack>

          <Text 
            className='position' 
            fontSize='14px' 
            lineHeight='150%' 
            fontWeight='500'>CFO</Text>


        </Stack>
      
        <Stack 
          boxShadow='lg' 
          p='2%' 
          bg={'#fff'}
        >
          <Image 
            src='seba.png' 
            alt='seba' 
            w='100%' 
            h='100%'
          />
          <HStack justifyContent={'center'}>
          <Text 
            className='name' 
            fontSize='20px' 
            lineHeight='120%' 
            fontWeight='700'>Seba</Text>
            <Link href='https://www.linkedin.com/in/sebasti%C3%A1n-ezequiel-larocca-b69b9813b/' target="_blank"><LinkedInIcon bg='primary' w='20px' h='20px' borderRadius='6px' _hover={{bg:'#dcf8c1'}} /></Link>
          </HStack>
          <Text 
            className='position' 
            fontSize='14px' 
            lineHeight='150%' 
            fontWeight='500'>COO</Text>
         

        </Stack>
      </Stack>
    </VStack>
    </ScaleFade>
  );
}

export default Team;