import React, { useEffect, useState } from 'react'
import { db } from '../firebase'
import { collection, getDocs, deleteDoc, doc} from 'firebase/firestore'
import {VStack, Stack,Text, Box, Button} from '@chakra-ui/react';

const Messages = () => {
  const [messageList, setMessageList] = useState([]);
  useEffect(() => {
    const messageCollectionRef = collection(db, 'contact');
    async function getMessages() {
      const data = await getDocs(messageCollectionRef);
      const messages = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
      setMessageList(messages)
    };
    getMessages()
  },[messageList]);
  const deleteMessage = async (id) => {
    const msgDoc = doc(db,'contact',id);
    await deleteDoc(msgDoc)
    setMessageList([])
  }
  return (
    <Stack mx={'20vw'}>
        <Text fontSize={'4xl'} my='10%' textAlign={'center'}>Mensajes</Text>
        <VStack  spacing='5%' mt='10%'>
            {messageList.map((message) =>{
              return(
              <VStack key={message.id} w='70%' borderRadius='20px' bgColor={'#E6E6E6'} align='left'>
                <Box p='6%' >
                    <Text fontSize={'xl'} py='2%'> Nombre: {message.name}</Text>
                    <Text fontSize={'lg'} py='1%'> Email: {message.email}</Text>
                    <Text fontSize={'lg'} py='1%'> Fecha : {message.fecha}</Text>
                    <Text fontSize={'lg'} py='1%'> Mensaje: {message.message}</Text>
                    <Button onClick={()=> {deleteMessage(message.id)}}>Borrar</Button>
                </Box>
              </VStack>)
            })}
        </VStack>    
    </Stack>
  )
}

export default Messages