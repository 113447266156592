import React, { useEffect, useState } from 'react'
import {
  Route,
  Link,
  Routes,
  HashRouter,
} from "react-router-dom";
import Blog from './Blog';
import Home from './Home';
import Post from './Post';
import Contact from './Contact';
import CreatePost from './CreatePost';
import { Stack, HStack, Box, Button, Image, Text, Icon, IconButton } from '@chakra-ui/react';
import { LanguageIcon } from './LanguageIcon';
import { MenuIcon } from './icons/menuIcon';
import { CloseIcon } from './icons/closeIcon';
import {HashLink} from 'react-router-hash-link'
import Messages from './Messages';
import ReactGA from 'react-ga';

const NavBar = () => {

  const langFile = require('./data/lang.json');
  const [lang,setLang] = useState('ES');
  const [currentLangData,setCurrentLangData] = useState(langFile[lang]);
  useEffect(() => {
    setCurrentLangData(langFile[lang]);
  }, [lang]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const [displayMenu, setDisplayMenu] = useState(false);

  const toogleLang = () => {
    if (lang === 'ES'){
      setLang('EN');
    }
    else{
      setLang('ES');
    }
  }

  return (
    <HashRouter >

      <Stack direction={['column','row','row','row']} spacing='0px' bg='primary' >
        <HStack w={'100%'} backgroundColor='primary' justifyContent='space-around' alignItems='center' color='white' height='4rem' >
          <Box>
          <Link to="/">
            <Image 
              src='/logo.png'
              boxSize='114px'
              objectFit='contain'
              alt='logo'
            >          
            </Image>
            </Link>
          </Box>
          <Stack direction={['row','row','row','row']} gap={2} align='center' >
            <Box display={['none','block','block','block']} overflow='hidden'>
              <Stack  direction={['column','row','row','row']} gap={2} >
                <HashLink to="/#services"> <Text fontWeight='500'> {currentLangData.nav[0]} </Text> </HashLink>
                <HashLink to="/#team"> <Text fontWeight='500'> {currentLangData.nav[1]} </Text> </HashLink>
                <Link to="/contact"> <Text fontWeight='500'> {currentLangData.nav[2]} </Text> </Link>
                <Link to="/blog"> <Text fontWeight='500'> {currentLangData.nav[3]} </Text> </Link>
              </Stack>
            </Box>
            {/* HStack */}
            <Button onClick={() => toogleLang()} bg={'primary'}> <Icon as={LanguageIcon} w={6} h={6} pr={2}/>{lang}</Button>
            <IconButton onClick={() => setDisplayMenu(!displayMenu)} icon={ displayMenu ? <CloseIcon /> : <MenuIcon />} size={'md'} display={['flex','none','none','none']} />  
          </Stack>        
        </HStack>

        <Stack direction={'column'} display={{base: displayMenu ? 'block' : 'none', md: 'none', lg: 'none', xl: 'none'}} p='3%' backgroundColor='primary' color='white'>
          
        <HashLink to="/#services"> <Text p='2%' fontWeight='500' fontSize='lg' align='center'> {currentLangData.nav[0]} </Text> </HashLink>
          <HashLink to="/#team"> <Text p='2%' fontWeight='500' fontSize='lg'align='center'> {currentLangData.nav[1]} </Text> </HashLink>
          <Link to="/contact"> <Text p='2%' fontWeight='500' fontSize='lg' align='center'> {currentLangData.nav[2]} </Text> </Link>
          <Link to="/blog"> <Text p='2%' fontWeight='500' fontSize='lg' align='center'> {currentLangData.nav[3]} </Text> </Link>

        </Stack>

      </Stack>


      <Routes>
        <Route path="/" element={<Home lang={currentLangData}/>}/>
        <Route path="/contact" element={<Contact text={currentLangData.contact}/>}/>
        <Route path="/blog" element={<Blog lang={lang} text={currentLangData.blog} />}/>
        <Route path="/messages" element={<Messages/>}/>
        <Route path="/blog/create-post" element={<CreatePost/>}/>
        <Route path="/blog/:id" element={<Post/>}/>
      </Routes>

    </HashRouter>
  )
}

export default NavBar;

