import React from 'react'
import Hero from './Hero';
import Services from './Services';
import MisionVision from './MisionVision';
import Contact from './Contact';
import Team from './Team';
import BlogCTA from './BlogCTA';
import Footer from './Footer';
import { Box} from '@chakra-ui/react'


const Home = ({ lang }) => {
  
  return (
    <Box>
    <Hero text={ lang.hero } />
    <Services text={ lang.services } />
    <Team text={ lang.team } />
    <MisionVision text={ lang.mision } />
    <Contact text={ lang.contact } />
    <BlogCTA text={ lang.blogContacto } />
    <Footer text={ lang.footer } />
    </Box>
  )
}

export default Home