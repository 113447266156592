import React from 'react'
import {Box, Heading, VStack,HStack, Text, Button } from '@chakra-ui/react'
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Hero = ({ text }) => {

  var md = require('markdown-it')({ breaks: true });
  const textHTML = md.render(text.mdtest);

  return (
    <Box overflow='hidden'>
      <VStack p={'5%'} textAlign='center' backgroundImage='/hero.png' backgroundSize={['cover']} backgroundPosition={['center','']} height='100vh' width='100vw' color='white' justify='center'>
        <Heading fontSize={['2rem','3rem']} textShadow={'1px 1px 2px #9BC86F'}> {text.tit} </Heading>
        <Text fontSize='15px' opacity={'95%'} w={['100%','40%']} dangerouslySetInnerHTML={{__html: textHTML}}></Text>
        <HStack py='20px' spacing='20px'>
          <HashLink to={'/#services'}>
            <Button backgroundColor='primary' px='30px' py='15px' fontWeight={600}> {text.serv} </Button>
          </HashLink>
          
          <Link to={'/contact'}>
            <Button color='primary'  px='30px' py='15px' fontWeight={600}> {text.contact} </Button>
          </Link>
        </HStack>        
      </VStack>
    </Box>  
  )
}

export default Hero