import React from 'react'
import { Box, Heading, IconButton, VStack,HStack, Text, Image, Link } from '@chakra-ui/react'
import { InstagramIcon } from './icons/instagramIcon'
import { LinkedInIcon } from './icons/linkedInIcon'
import { EmailIcon } from './icons/emailIcon'

const Footer = ({ text }) => {
  return (
    <VStack backgroundColor='primary' color='white' padding={10} justifyContent='center'>
     <Heading fontSize={['xl', '2xl', '2xl', '2xl']} textAlign='center' letterSpacing={'0.04px'}>{text[0]}</Heading>
     <Box>
       <Text textAlign={'center'} size='sm' letterSpacing={'wide'}>
         {text[1]}
       </Text>
       <HStack justify='center' padding={'5%'} spacing='4%'>

        <Link href='https://www.instagram.com/qpampa_/' target='_blank'>
          <IconButton size='md' bg={'primary'} px={'1%'} icon={<InstagramIcon w='95%' h='95%' />}  _hover={''} />
        </Link>      
        <Link href='https://www.linkedin.com/company/80552164' target='_blank'>
          <IconButton size='md'bg={'primary'} px={'1%'} icon={<LinkedInIcon w='90%' h='90%' />}  _hover={''} />
        </Link>
        <Link href='mailto:contacto@q-pampa.com' target='_blank'>
          <IconButton size='md'bg={'primary'} px={'1%'} icon={<EmailIcon w='90%' h='90%' />}  _hover={''} />
        </Link>
       </HStack>
     </Box>
     <Image src='/logo.png' boxSize={['40%','15%']}/>
    </VStack>
  )
}

export default Footer