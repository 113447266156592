import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
 
  margin: {
    
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  colors:{
    primary: '#9BC86F',
    white: '#FAFAFA',
    black: '#212121'
  },
  styles: {
    global:{
      body:{
        fontSize: '13px',
        letterSpacing: '.06',
        lineHeight: '150%',
        fontWeight: '400'
      },
    }
  }
})