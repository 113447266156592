import React, { useEffect, useState } from 'react'
import {Link} from "react-router-dom";
import { db } from '../firebase'

import { collection,getDocs, query, where} from 'firebase/firestore'
import { Heading, VStack, Stack,Text, Box, Button, Image,} from '@chakra-ui/react';



const Blog = ({ lang, text }) => {
  const [postList, setPostList] = useState([]);

  var md = require('markdown-it')({ breaks: true });
  const textDesc = md.render(text.desc);

  useEffect(() => {
    const postCollectionRef = collection(db, 'posts');
    console.log(postCollectionRef)
    async function getPosts() {
      const q = query(postCollectionRef, where('lang', '==', lang))
      const data = await getDocs(q);
      const posts = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
      setPostList(posts)
    };
    getPosts()
  },[lang]);
  return (
    <Stack mx={['7vw','20vw']} overflow={'hidden'}>
      <VStack mt={['20%','10%']}>
        <Box mb={'10%'} align='center'>
          <Image src='logo2.png' w={['200px','300px']}></Image>
          <Text fontSize={['md','lg']} py='5%' dangerouslySetInnerHTML={{__html: textDesc}}></Text>
        </Box>
        <VStack gap={['10vh','20vh']} mt={['20%']} >
            {postList.map((post) =>{
              return(
              <VStack key={post.id} align='center'>
                <Box alignSelf={['center']} mb={['3%','2%']}>
                  <Heading textAlign={['center','center']} textColor='primary' fontWeight={300}>{post.title}</Heading>
                  {/*<Text textAlign={['center','left']}>{post.author}</Text>*/}
                </Box>
                <Box align={'center'}>
                 <Image src={post.bgImageUrl} w={['45%','80%']}  objectFit={'contain'}></Image>
                </Box>
                <Text p={'2%'} dangerouslySetInnerHTML={{__html: post.body}} h={['10vh','12vh']} w={['90%','80%']} overflow='hidden' align={'justify'}></Text>
                <Text p={'1%'} align='center' fontSize={'lg'}>...</Text>
                <Link to= {'/blog/'+post.id}>
                <Button bg={'primary'} color='white' _hover={{bg:'white', color:'primary', border:'2px', borderColor: 'primary'}} mb='30%'>{text.boton}</Button>
                </Link>
              </VStack>)
            })}
        </VStack>
      </VStack>
    </Stack>
  )
}

export default Blog