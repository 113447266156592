import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import {Stack,Divider,Box,Heading,Image,Text, Fade} from '@chakra-ui/react' 

const MisionVision = ({ text }) => {

  const ref = useRef(null);
  const { enterCount } = useInViewport(
    ref,
    { rootMargin: "-100px" },
    { disconnectOnLeave: false },
    {}
  );


  return (
    <Box>
    <Stack pt={'5%'} mx={['10vw','12vw']} >
        <Box pos="relative" top={['50%','50%','15vh','30vh']} left={['120vw', '2vw']} display={['none','none','block','block']}>
          <Image src='/molecula.png'
                  boxSize='50%'
                  w={['40%','40%','40%','40%']}
                  opacity='50%' />
        </Box>

      <Fade
         in={enterCount > 0}
         initialscale={0.9}>          
      <Stack direction={['column-reverse','column-reverse','row','row']} p='2%' spacing='10%' ref={ref}>

        <Stack direction='column' spacing='5%' justifyContent='center' maxW={['100%','50%']} p='1%' py={['22%']} pt={['20%','28%']}>
          <Heading fontSize='2xl' fontWeight='700' textAlign={['center','left']} >{text.m1[0]}</Heading>
          <Text textAlign={['justify','left']}> {text.m1[1]} <br /> {text.m1[2]} </Text>
          <Divider orientation='horizontal' />
          <Heading fontSize='2xl' fontWeight='700' textAlign={['center','left']}> {text.m2[0]} </Heading>
          <Text textAlign={['justify','left']}>{text.m2[1]}</Text>         
        </Stack>
        <Box boxSize={['100%','70%','70%','70%']} >
          
          <Heading textAlign={['center','right']} fontSize={['7xl','8xl','8xl','8xl']} fontWeight='400' pb='5%'> {text.mtit} </Heading>
          <Box>
            <Image src='/mision.png'boxSize={'100%'} boxShadow='2px 2px 10px rgba(100,100,100, 0.2)'/>
          </Box>
        </Box>
      </Stack>
      </Fade>
      </Stack>
      <Stack mx={['10vw','12vw']} mb={['1%','0']} pos='relative'>
      <Box pos="absolute" top={['50%','50%','15vh','10vh']} left={['120vw', '38vw']} display={['none','none','block','block']} w='50%'>
              <Image src='/molecula.png'
                      boxSize='100%'
                      w={['40%','40%','40%','82%']}
                      opacity='50%'                     
                      />
        </Box>

              <Stack direction={['column','column','row','row']} p='2%' spacing='10%' >
        
        <Box>
          <Heading textAlign={['center','left']} fontSize={['7xl','8xl','8xl','8xl']} fontWeight='400' py='10%' > {text.vtit} </Heading>
          <Image src='/vision.png' boxShadow='2px 2px 10px rgba(100,100,100, 0.2)' />         
        </Box>
          <Stack direction='column' spacing={['10%','5%']} justifyContent='center' maxW={['100%','50%']} p='2%' pt={['22%','16%']}>
          <Heading fontSize='2xl' fontWeight='700' textAlign={['center','right']}> {text.v1[0]} </Heading>
          <Text textAlign={['justify','right']}> {text.v1[1]} </Text>
          <Divider orientation='horizontal' />
          <Heading fontSize='2xl' fontWeight='700' textAlign={['center','right']}> {text.v2[0]} </Heading>
          <Text textAlign={['justify','right']}> {text.v2[1]} </Text> 
          </Stack>
      </Stack>
      <Divider orientation="horizontal" display={['block','none']}  />
      </Stack>
    </Box>
  )
}

export default MisionVision