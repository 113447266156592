import React from "react";
import { useForm } from "react-hook-form";
import {
  FormLabel,
  FormControl,
  Input,
  Button,
  Stack,
  Box,
  Text,
  Heading,
  Textarea,
  Image,
  Divider,

} from '@chakra-ui/react'
import { db } from '../firebase'
import { addDoc, collection } from 'firebase/firestore'
import useAnalyticsEventTracker from './useAnalyticsEventTracker';

const Contact = ({ text }) => {
  const gaEventTracker = useAnalyticsEventTracker('Contact Category');
  const contactCollectionRef = collection(db, 'contact');
  const { register, handleSubmit, reset, formState: {errors } } = useForm();
  
  const onSubmit = async (data) => {
    gaEventTracker('Submit')
    let date = new Date(Date.now())
    await addDoc(contactCollectionRef, {
        name:data.name,
        lastname:data.lastname,
        email:data.email,
        message:data.textAreaID, 
        fecha: date.toLocaleString()
    });
    alert('Enviado')
    reset({name:'',lastname:'',email:'',textAreaID:''})  
  }
 
  return (
    
    <Stack
      my={'15vh'}
      spacing={'10%'} 
      direction={['column','row']} 
      mx={['10vw','20vw']}
    >
        <Stack maxW={['100%','50%']} justifyContent={'space-around'}>
        <Image 
                src='/contact-image.svg'
                alt='contact-image'  
                w={'30%'}
                display={['flex', 'none']}
                alignSelf={'center'}
                pb={'5em'}
            />
            <Heading 
                color='black' 
                fontWeight={'800'} 
                textAlign={['center','left']}
                fontSize={['24px', '36px']}
                pb={['5%', '0']}
                >{text.tit}
            </Heading>
            <Divider orientation='horizontal' display={['none','block']}/>
            <Text 
                textAlign={['justify','left']}
                pb={['5%', '0']}
                >{text.desc.p1}
            </Text>
            <Divider orientation='horizontal' display={['none','block']}/>
            <Text 
                textAlign={['justify','left']}
                pb={['5%', '0']}
                >{text.desc.p2}
            </Text>
            <Divider orientation='horizontal' display={['none','block']}/>
            <Text 
                textAlign={['justify','left']}
                pb={2}
                >{text.desc.p3}
            </Text>
            <Image 
                src='/contact-image.svg'
                alt='contact-image'  
                w={'30%'}
                display={['none', 'flex']}
                alignSelf={'center'}
            />
        </Stack>
        <Box w={'100%'} alignItems='center'>
            <form onSubmit={handleSubmit(onSubmit)} align='justify'>
                <FormControl    
                    border='1px'
                    borderColor='gray.300'
                    bg="#9BC86F"
                    px={8} 
                    py={20}
                    borderRadius='2xl'
                    boxShadow='xl'
                >
                <Image 
                    src='/logo.png' 
                    boxSize={['60%','55%']} 
                    ml="25%" 
                    mb='1em'
                />
                <FormLabel 
                    htmlFor='firstname' 
                    color={'#FAFAFA'} 
                    fontSize={'14px'} 
                    mb={0} 
                    fontWeight={'300'}
                    >{text.n}
                </FormLabel>
                <Input 
                    color='white'
                    variant='flushed'
                    id='name'
                    mb='1em'        
                    {...register('name', {
                    required: 'This is required',
                    minLength: { value: 4 },
                    })}
                />
                {errors.name?.type === 'required' && <Text color={'#d02929'} fontSize={'11px'} mb={'1em'} textAlign={'left'}>{text.error.name}</Text>}
                <FormLabel 
                    htmlFor='lastname' 
                    color={'#FAFAFA'} 
                    fontSize={'14px'} 
                    mb={0} 
                    fontWeight={'300'} 
                    letterSpacing={'.06'} 
                    lineHeight={'150%'}
                    >{text.a}
                </FormLabel>
                <Input
                    color='white'
                    variant='flushed'
                    id='lastname'
                    mb='1em'
                    errorBorderColor='red.300'
                    {...register('lastname', {
                    required: 'This is required',
                    minLength: { value: 4 }
                    })}
                />
                {errors.lastname?.type === 'required' && <Text color={'#d02929'} fontSize={'11px'} mb={'1em'} textAlign={'left'}>{text.error.lastname}</Text>}
                <FormLabel 
                htmlFor='email' 
                color={'#FAFAFA'} 
                fontSize={'13px'} 
                mb={0} 
                fontWeight={'300'}
                >Email
                </FormLabel>
                <Input
                    color='white'
                    variant='flushed'
                    id='name'
                    mb='1em'
                    errorBorderColor='red.300'
                    {...register('email', {
                    required: 'This is required',
                    minLength: { value: 4 },
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
                    })}
                />
                {errors.email?.type === 'pattern' && <Text color={'#d02929'} fontSize={'11px'} mb={'1em'} textAlign={'left'}>{text.error.email}</Text>}
                <FormLabel 
                    htmlFor='message' 
                    color={'white'} 
                    fontWeight={'400'}
                    fontSize={'13px'}
                    >{text.msg}
                </FormLabel>
                <Textarea 
                     resize={'none'}
                     color='white' {...register("textAreaID", {
                        maxLength: { value: 300}
                     })} />
                <Box align='center'>
                    <Button 
                        mt={'10%'}    
                        px={'55px'}  
                        borderRadius={'50px'}     
                        type="submit" 
                        value="submit" 
                        variant='outline' 
                        color={'white'}
                        align='center'
                        justifyItems={'center'}    
                        _hover={{
                            color:'primary',
                            bg:'white'
                        }}
                        >{text.send}
                    </Button>
                </Box>
                </FormControl>
            </form> 
      </Box>
    </Stack>
  )}

export default Contact