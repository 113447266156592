import { createIcon } from "@chakra-ui/react";

export const EmailIcon = createIcon({
    displayName: 'EmailIcon',
    viewBox: '0 0 52 38',
    fill:'none',
    path: ([
        <path d="M47.7704 0.875H4.27039C3.30898 0.875 2.38694 1.25692 1.70712 1.93674C1.0273 2.61656 0.645386 3.53859 0.645386 4.5V33.5C0.645386 34.4614 1.0273 35.3834 1.70712 36.0633C2.38694 36.7431 3.30898 37.125 4.27039 37.125H47.7704C48.7318 37.125 49.6538 36.7431 50.3336 36.0633C51.0135 35.3834 51.3954 34.4614 51.3954 33.5V4.5C51.3954 3.53859 51.0135 2.61656 50.3336 1.93674C49.6538 1.25692 48.7318 0.875 47.7704 0.875ZM43.7829 4.5L26.0204 16.7887L8.25789 4.5H43.7829ZM4.27039 33.5V6.14937L24.9873 20.4862C25.2907 20.6967 25.6511 20.8095 26.0204 20.8095C26.3897 20.8095 26.7501 20.6967 27.0535 20.4862L47.7704 6.14937V33.5H4.27039Z" fill="#FAFAFA"/>
    ]),
  })
