import React from 'react'
import { Flex, Stack, Spacer, Text, Image, Button, Box, Heading } from '@chakra-ui/react'
import { Link } from 'react-router-dom'


const BlogCTA = ({ text }) => {
  
  return (
    <Box ml={['7%','10%']} mr={['7%','20%']} my={'7%'}>

      <Flex direction={['column','row']} justifyContent='center'>
        
        <Box maxW={['100%','60%']}>
          <Image src='reading.png' alt='reading' />
        </Box>  
        
        <Stack direction='column' spacing='9%'  maxW={['100%','40%']}>
          <Spacer />
          <Heading fontWeight={'800'} textAlign={['center', 'left', 'left', 'left']}>
            {text.tit}
          </Heading>
          <Text align={['justify', 'left']}>
            {text.desc}   
          </Text>
          <Box pl={'30%'}>
            <Link to={'/blog'}>
              <Button w={['50%','50%']} align='center' bgColor={'primary'} color={'white'} 
                      borderRadius='30px' _hover={{bg: ''}} px={'55px'} py={'13'}>
                
                <Text fontSize={'15px'}>
                  {text.boton}
                </Text>
              </Button>
          
            </Link>
            
          </Box>
          <Spacer/>
        </Stack>   
         
      </Flex>

    </Box>
  )
}

export default BlogCTA;