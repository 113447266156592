
import React, { useState, useEffect }from 'react'
import { useParams } from "react-router-dom";
import { db } from '../firebase'
import { getDoc, doc} from 'firebase/firestore'
import {Stack,VStack,Heading,Text,Image} from '@chakra-ui/react'



const Post = () => {
  const {id}  = useParams();
  const [post, setPost] = useState({});

  useEffect(() => {

    const getPost = async () => {
      const docRef = doc(db, 'posts', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPost(docSnap.data())
      } else {
        console.log("No such document!");
      }
    }
    getPost();
  },[])


  return (
    <Stack mx={'20vw'}>
       
        <VStack py={'10%'}>
        <Image py={'5%'} w={'70%'} src={post.imageUrl}></Image>
            <Heading >{post.title}</Heading>
            <Text dangerouslySetInnerHTML={{__html: post.body}} ></Text>
          </VStack>

    </Stack>
    
  )
}

export default Post