import { createIcon } from "@chakra-ui/react";

export const LinkedInIcon = createIcon({
    displayName: 'LinkedInIcon',
    viewBox: '0 0 42 42',
    fill:'none',
    path: ([
        <path d="M38.3845 0.285645H3.61553C1.77713 0.285645 0.285706 1.77707 0.285706 3.61547V38.3844C0.285706 40.2228 1.77713 41.7142 3.61553 41.7142H38.3845C40.2228 41.7142 41.7143 40.2228 41.7143 38.3844V3.61547C41.7143 1.77707 40.2228 0.285645 38.3845 0.285645ZM38.3845 38.3999C15.1948 38.3947 3.59999 38.3896 3.59999 38.3844C3.60517 15.1948 3.61035 3.59993 3.61553 3.59993C26.8052 3.60511 38.4 3.61029 38.4 3.61547C38.3948 26.8051 38.3896 38.3999 38.3845 38.3999ZM6.42749 15.8162H12.5745V35.588H6.42749V15.8162ZM9.50356 13.113C11.4662 13.113 13.0664 11.518 13.0664 9.55011C13.0664 9.08223 12.9743 8.61893 12.7952 8.18666C12.6162 7.7544 12.3537 7.36163 12.0229 7.03079C11.692 6.69995 11.2993 6.43751 10.867 6.25846C10.4347 6.07941 9.97144 5.98725 9.50356 5.98725C9.03568 5.98725 8.57238 6.07941 8.14012 6.25846C7.70785 6.43751 7.31508 6.69995 6.98424 7.03079C6.6534 7.36163 6.39096 7.7544 6.21191 8.18666C6.03286 8.61893 5.94071 9.08223 5.94071 9.55011C5.93553 11.518 7.53053 13.113 9.50356 13.113ZM22.5691 25.8056C22.5691 23.2267 23.0611 20.7306 26.2562 20.7306C29.4048 20.7306 29.4514 23.6772 29.4514 25.9714V35.588H35.5932V24.744C35.5932 19.4205 34.4436 15.3242 28.2241 15.3242C25.2361 15.3242 23.232 16.9658 22.4086 18.5194H22.3257V15.8162H16.4273V35.588H22.5691V25.8056Z" fill="#FAFAFA"/>
    ]),
  })
