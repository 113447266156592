import { createIcon } from "@chakra-ui/react";

export const MenuIcon = createIcon({
    displayName: 'MenuIcon',
    viewBox:'0 0 64 45',
    fill:'none',
    path: ([
      <path d="M0 3.162C0 1.41569 1.41569 0 3.162 0H60.078C61.8244 0 63.24 1.41569 63.24 3.162C63.24 4.90831 61.8244 6.324 60.078 6.324H3.162C1.41569 6.324 0 4.90831 0 3.162Z" fill="black"/>,
      <path d="M0 22.2358C0 20.4894 1.41569 19.0738 3.162 19.0738H60.078C61.8244 19.0738 63.24 20.4894 63.24 22.2358C63.24 23.9822 61.8244 25.3978 60.078 25.3978H3.162C1.41569 25.3978 0 23.9822 0 22.2358Z" fill="black"/>,
      <path d="M3.162 38.148C1.41569 38.148 0 39.5636 0 41.31C0 43.056 1.41569 44.472 3.162 44.472H60.078C61.8244 44.472 63.24 43.056 63.24 41.31C63.24 39.5636 61.8244 38.148 60.078 38.148H3.162Z" fill="black"/>,
      <path d="M0 3.162C0 1.41569 1.41569 0 3.162 0H60.078C61.8244 0 63.24 1.41569 63.24 3.162C63.24 4.90831 61.8244 6.324 60.078 6.324H3.162C1.41569 6.324 0 4.90831 0 3.162Z" fill="black"/>,
      <path d="M0 22.2358C0 20.4894 1.41569 19.0738 3.162 19.0738H60.078C61.8244 19.0738 63.24 20.4894 63.24 22.2358C63.24 23.9822 61.8244 25.3978 60.078 25.3978H3.162C1.41569 25.3978 0 23.9822 0 22.2358Z" fill="black"/>,
      <path d="M3.162 38.148C1.41569 38.148 0 39.5636 0 41.31C0 43.056 1.41569 44.472 3.162 44.472H60.078C61.8244 44.472 63.24 43.056 63.24 41.31C63.24 39.5636 61.8244 38.148 60.078 38.148H3.162Z" fill="black"/>
    ]),

  })